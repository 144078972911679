@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: rgb(18, 18, 18);
  color: rgba(255, 255, 255, 0.87);
  font-size: 1rem;
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.5rem;
  font-weight: 400;
  text-rendering: optimizelegibility;
}

html {
  scroll-behavior: smooth;
}

h1 {
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #a162e8 0%,
    #ee4949 46%,
    #dc75fb 100%
  );

  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h3 {
  font-size: 1.4rem !important;
  background-color: #8e9ffa;
  background-image: linear-gradient(
    45deg,
    #8e9ffa 0%,
    #f874ef 10%,
    #ffcc70 29%,
    #ffffff 100%
  );

  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.patterns {
  background-image: url("./images/bg-effects.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

input {
  height: 40px;
}

.gradient:focus {
  border: 1px solid transparent;
  background-image: linear-gradient(#141414, #141414),
    linear-gradient(43deg, #a162e8 0%, #f7ce68 46%, #85ffbd 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

input,
textarea {
  background: #121212c5;
  border-radius: 0.3rem;
  border: 1px solid transparent;
}

*:focus:not(.focus-visible) {
  outline: none;
}

/* Loader */

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
